// import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState, useContext } from "react"
import styled, { keyframes } from 'styled-components'
import media from '../styles/media'
import LoadContext from '../context/loadContext'

import Load1 from '../images/load1.jpg'
import Load2 from '../images/load2.jpg'

const textMove = keyframes`
  90% {
    top: -296px;
  }
  100% {
    top: -296px;
  }
`

const textMoveMob = keyframes`
  90% {
    top: -180px;
  }
  100% {
    top: -180px;
  }
`

const image1Move = keyframes`
  30% {
    top: -296px;
  }
  90% {
    top: -296px;
  }
  100% {
    top: -592px;
  }
`
const image1MoveMob = keyframes`
  30% {
    top: -180px;
  }
  90% {
    top: -180px;
  }
  100% {
    top: -365px;
  }
`

const image2Move = keyframes`
  25% {
    top: -596px;
  }
  50% {
    top: -596px;
    filter: grayscale(0);
  }
  75% {
    top: -596px;
    filter: grayscale(1);
  }
  100% {
    top: -892px;
    filter: grayscale(1);
  }
`
const image2MoveMob = keyframes`
  25% {
    top: -365px;
  }
  50% {
    top: -365px;
    filter: grayscale(0);
  }
  75% {
    top: -365px;
    filter: grayscale(1);
  }
  100% {
    top: -725px;
    filter: grayscale(1);
  }
`

const LoadingCon = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background: #181818;
  color: white;
  z-index: 999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
    span {
      display: flex;
      font-size: 12vw;
      line-height: 81px;
      margin-bottom: 0;
      align-items: center;
      width: 100%;
      margin: auto 0;
      height: 180px;
      justify-content: center;
      &.percent  {
        position: initial;
        &.hide {
          opacity: 0 !important;
        }
      }
    }

    .container {
      width: 90vw;
      padding: 0 5vw;
      height: 180px;
      margin: auto;
      overflow: hidden;

      span {
        position: relative;
        top: 0;
        left: 0;
        &.yes {
          animation: ${textMoveMob} 5s forwards;
          animation-delay: 3s;
        }
      }
      img {
        margin: 0;
        margin-bottom: 0;
        object-fit: cover;
      }
      .load1 {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
        animation: ${image1MoveMob} 3s forwards;
        animation-delay: 1.5s; 
      }
      .load2 {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
        animation: ${image2MoveMob} 3s forwards;
        animation-delay: 3s;
      }
    }

  &.disable {
    transition: 700ms ease-out;
    opacity: 0;
    z-index: -3;
    span {
      opacity: 0;
    }
  }

  ${media.tablet`
    height: 100vh;
    span {
      font-size: 67.34px;
      line-height: 81px;
      margin-bottom: 0;
      width: 500px;
      height: 296px;
      justify-content: center;
      &.percent  {
        position: initial;
      }
    }

    .container {
      width: 500px;
      height: 296px;
      padding: 0;
      margin: auto;
      overflow: hidden;

      span {
        position: relative;
        top: 0;
        left: 0;
        &.yes {
        animation: ${textMove} 5s forwards;
        animation-delay: 3s;
        }
      }
      .load1 {
        width: 100%;
        height: 100%;
        margin: 0;
        position: relative;
        top: 0;
        left: 0;
        animation: ${image1Move} 3s forwards;
        animation-delay: 1s;
      }
      .load2 {
        width: 100%;
        height: 100%;
        margin: 0;
        position: relative;
        top: 0;
        left: 0;
        animation: ${image2Move} 3s forwards;
        animation-delay: 2.5s;
      }
    }
  `}
`

const Loading = props => {
  const [count, setCount] = useState(0)
  const load = useContext(LoadContext);
  const [showImg, setShowImg] = useState(false)

  useEffect(() => {
    var countNo = 0;
    var counting = setInterval(function () {
      if (countNo < 101) {
        setCount(countNo++)
      } else {
        clearInterval(counting)
        setShowImg(true)

        setTimeout(() => {
          load.updateLoadVisible(false)
        }, 6000);
      }
    }, 15);
  }, [load])

  return (
    <LoadingCon className={load.load ? "" : "disable"}>
      <div className="container">
        <span className={showImg ? 'percent yes' : 'percent'}>{count}%</span>
        {showImg && (
          <>
            <img src={Load1} className="load1" alt="load1" />
            <img src={Load2} className="load2" alt="load2" />
          </>
        )}
      </div>
    </LoadingCon>
  );
}
export default Loading;