import * as React from "react"
import { useEffect, useState } from 'react';

import LoadContext from '../context/loadContext'
import LanguageContext from '../context/languageContext'
import ProductContext from '../context/productContext'

import "./layout.css"
import '../styles/theme.css'

import Loading from "./Loader"


const Layout = ({ children }) => {
  const [load, setLoad] = useState(true);
  const [language, setLanguage] = useState('english');
  const [product, setProduct] = useState('');

  useEffect(() => {
    if (window !== 'undefined') {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--blend', `none`);
    }
  }, [])

  const updateLoadVisible = () => {
    setLoad(false)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)    
      if(localStorage.language === '') {
        localStorage.setItem("language", 'english')
      }
    }
  }, [])

  const updateLang = (newLang) => {
    console.log('update Lang');
    setLanguage(newLang);
    localStorage.setItem("language", newLang)
    console.log(localStorage.language);
  }

  const updateProd = (newProd) => {
    setProduct(newProd);
    localStorage.setItem("product", newProd)
  }

  return (
    <>
      <LanguageContext.Provider value={{ language, updateLang }}>
        <ProductContext.Provider value={{ product, updateProd }}>
          <LoadContext.Provider value={{ load, updateLoadVisible }}>
            <Loading active={load.load} />
            <main>{children}</main>
          </LoadContext.Provider>
        </ProductContext.Provider>
      </LanguageContext.Provider>
    </>
  )
}

export default Layout
